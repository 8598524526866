import React from 'react';
import { Router } from '@reach/router';
import { useBeforeUnload } from 'react-use';
import {
  TicketCheckout,
  CardAddFunds,
  Confirmation,
} from '../components/routes';
// import NotFound from './404';

const AddFundsPage = () => {
  useBeforeUnload('Enable', 'You have unsaved changes, are you sure?');
  return (
    <Router basepath="add-funds">
      <CardAddFunds path=":id" />
      <TicketCheckout path=":id/checkout" />
      <Confirmation path=":id/confirmation" />
      {/* <NotFound default /> */}
    </Router>
  );
};

export default AddFundsPage;
